import { X } from "lucide-react"
import { IoClose } from 'react-icons/io5'
interface Props {
    closeModal?: () => void
    children: React.ReactNode,
    title?: string
    hideCloseButton?: boolean
}


const Modal = ({ closeModal, children, title, hideCloseButton }: Props) => {
    return (
        <div className='absolute h-[110vh] inset-0 flex center z-[1000] bg-gray-800/60 backdrop-blur-md px-2 '>
            <div className='max-h-[90%]  flex flex-col bg-white p-2  rounded-xl overflow-scroll scrollbar-hidden'>
                {!hideCloseButton && closeModal && (
                    <div className='w-full flex justify-between gap-4 p-4'>
                    <h1 className='font-bold text-xl text-foreground'>{title}</h1>
                    <X onClick={closeModal} className=" rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground cursor-pointer"/>
                    </div>
                )

                }
                <div className='flex flex-col items-center bg-white md:m-4 m-0'>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
