import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentAddress: {},
  savedAddresses: [] as any[],  // Array to store multiple saved addresses
  nearestBranch: null,
  initialLocation: {
    location: null,  // { latitude: number, longitude: number }
    address: null,   // string representing the textual address
  },
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    // Set the initial location from the browser (separate from currentAddress)
    setInitialLocation: (state, action) => {
      const { location, address } = action.payload;
      state.initialLocation = { location, address };
    },

    // Set the current address for delivery
    setCurrentAddress: (state, action) => {
      state.currentAddress = action.payload;
    },

    // Add a new address to the saved addresses
    addSavedAddress: (state, action) => {
      if (!Array.isArray(state.savedAddresses)) {
        console.error("savedAddresses is not an array: ", state.savedAddresses);
        state.savedAddresses = []; // Reset to an empty array if it's undefined
      }
      const newAddress = action.payload;
      if (!state.savedAddresses.some(addr => addr.id === newAddress.id)) {
        state.savedAddresses.push(newAddress);
      }
    },
    // Set the nearest branch based on the current location
    setNearestBranch: (state, action) => {
      state.nearestBranch = action.payload;
    },
    deleteFromSavedAddresses: (state, action) => {
      state.savedAddresses = state.savedAddresses.filter(address => address.id !== action.payload);
    },
    deleteCurrentAddress: (state) => {
      state.currentAddress = {};
    },

    // Clear all location history (for logout)
    clearLocationHistory: (state) => {
      state.currentAddress = initialState.currentAddress;
      state.initialLocation = initialState.initialLocation;
      state.savedAddresses = initialState.savedAddresses;
      state.nearestBranch = initialState.nearestBranch;
    },
  },
});

export const {
  setInitialLocation,
  setCurrentAddress,
  addSavedAddress,
  setNearestBranch,
  clearLocationHistory,
  deleteCurrentAddress,
  deleteFromSavedAddresses
} = locationSlice.actions;

export default locationSlice.reducer;
