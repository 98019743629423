"use client"
import { getSettings } from '@/lib/apiRequests';
import React, { createContext, useContext, useState, useEffect } from 'react';
import {useRouter} from "next/navigation";

// Create the context
const SettingsContext = createContext(null);

// Create the provider component
export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState(null);
  const [settingsLoading, setSettingsLoading] = useState(true);
  const [error, setError] = useState(null);
const router = useRouter()
  useEffect(() => {
    const fetchSettings = async () => {
      setSettingsLoading(true);
      setError(null); // Reset error state before fetching

      try {
        const data = await getSettings();
        if (data?.records) {
          setSettings(data.records[0]);
        } else {
          setError('We are only operating in Pakistan, and have geolocation based restrictions to access the website');
        }
      } catch (err) {
        setError('Unable to fetch settings');
      } finally {
        setSettingsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  return (
    <SettingsContext.Provider value={{ settings, settingsLoading, error }}>
      {children}
    </SettingsContext.Provider>
  );
};

// Create a custom hook to use the SettingsContext
export const useSettings = () => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};
