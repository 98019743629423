// This context is used to store the state for dialogue box for login/register
'use client'
import React, { ReactNode, createContext, useContext, useState } from 'react';


type ModalType = 'login' | 'register' | null;
interface IAuthModalContext {
    modalType: ModalType;
    openLoginModal: () => void;
    openRegisterModal: () => void;
    closeModal: () => void;
}
interface AuthModalProviderProviderProps {
    children: ReactNode;
}

const AuthModalContext = createContext<IAuthModalContext | undefined>(undefined);

export const AuthModalProvider: React.FC<AuthModalProviderProviderProps> = ({ children }) => {
    const [modalType, setModalType] = useState<ModalType>(null);

    const openLoginModal = () => {
        setModalType('login');
    };

    const openRegisterModal = () => {
        setModalType('register');
    };

    const closeModal = () => {
        setModalType(null);
    };

    return (
        <AuthModalContext.Provider value={{ modalType, openLoginModal, openRegisterModal, closeModal }}>
            {children}
        </AuthModalContext.Provider>
    );
};

// creating a custom hook
export const useAuthModal = () => {
    const context = useContext(AuthModalContext);
    if (!context) {
        throw new Error('useAuthModal must be used within a AuthModalProvider');
    }
    return context
};