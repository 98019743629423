import { createSlice, } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../store/store'
import { compareVariations } from "@/lib/helperFunctions";

const initialState: CartState = {
    cart: [],
    totalQuantity: 0,
    totalAmount: 0
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        add(state, action: PayloadAction<CartProduct>) {            
            const index = state.cart.findIndex(item => item.id === action.payload.id);           
          
            if (index !== -1) {            
              const existingProduct = state.cart[index];
              const hasSameVariations = compareVariations(existingProduct?.variationIds!.map(Number),
              action.payload?.variationIds!.map(Number));
              
              if (hasSameVariations) {                  
                // Increase quantity if variations are the same
                state.cart[index].quantity! += action.payload.quantity!;
              } else {
                // Add new product if variations are different                
                state.cart.push(action.payload);
              }
            } else {                
              state.cart.push(action.payload);
            }
          },


        remove(state,action){
            state.cart = state.cart.filter((item:CartProduct,index) => index !== action.payload)
        },

        // calculate the total amount and total quantitiy of items in cart
        getCartTotal(state) {
            let { totalAmount, totalQuantity } = state.cart.reduce(
                (cartTotal: any, cartItems: any) => {
                    // console.log("totalAmount: ", totalAmount)
                    // console.log("totalQuantity: ", totalQuantity)
                    //    console.log("cartTotal: ", cartTotal)
                    //    console.log("cartItems: ", cartItems)
                    const { totalPrice, quantity } = cartItems
                    // console.log(price,quantity)
                    const itemTotal = totalPrice * quantity
                    cartTotal.totalAmount += itemTotal
                    cartTotal.totalQuantity += quantity
                    return cartTotal
                },
                {
                    totalAmount: 0,
                    totalQuantity: 0
                }
            )
            state.totalAmount = parseInt(totalAmount.toFixed(2))
            state.totalQuantity = totalQuantity
        },

        /* NOTE: the commented code below was increasing and decreasing quantity based on id which was causing error when same product was selected
                 multiple times with different vairations. the new code uses index to increase or decrease quantity */
        increaseProductQuantity (state,action){
            // let find = state.cart.findIndex((item: CartProduct) => item.id === action.payload)
            // ++state.cart[find].quantity!
            ++state.cart[action.payload].quantity!
        },

        decreaseProductQuantity (state,action){
            
            // let find = state.cart.findIndex((item: CartProduct) => item.id === action.payload)
            // checking if the quantity is greater than 1 then decrease it by 1. If it is 1 then remove the item from cart
            if(state.cart[action.payload].quantity! > 1){
                --state.cart[action.payload].quantity!
            }else{
                    // state.cart = state.cart.filter((item:CartProduct) => item.id !== action.payload);
                    state.cart.splice(action.payload, 1);
                }
        },
        emptyCart(state) {
            state.cart = [];
            state.totalQuantity = 0;
            state.totalAmount = 0;
        },
    }
})



export const { add ,remove, getCartTotal, increaseProductQuantity, decreaseProductQuantity,emptyCart } = cartSlice.actions
export default cartSlice.reducer