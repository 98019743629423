"use client"
import { useAppSelector } from '@/lib/hooks';
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AddAddressModalContextProps {
  addAddressModal: boolean;
  setAddAddressModal: React.Dispatch<React.SetStateAction<boolean>>;
  initialModal: boolean;
  setInitialModal: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context with default values as undefined
const AddAddressModalContext = createContext<AddAddressModalContextProps | undefined>(undefined);

// Create the provider component
export const AddAddressModalProvider = ({ children }: { children: ReactNode }) => {
  //@ts-ignore
  const currentAddress = useAppSelector((state) => state.location.currentAddress);
  const [addAddressModal, setAddAddressModal] = useState<boolean>(false); // State to control the on/off of the map which allows the user to add a new address
  const [initialModal, setInitialModal] = useState<boolean>(() => {
    if (currentAddress && Object.keys(currentAddress).length === 0) {
      return true;
    }
    return false;
  });
   // State to control the on/off of the initial modal (the dialogue box that appear when user lands on website)

  return (
    <AddAddressModalContext.Provider value={{ addAddressModal, setAddAddressModal, initialModal, setInitialModal }}>
      {children}
    </AddAddressModalContext.Provider>
  );
};

// Create a custom hook to use the AddAddressModalContext
export const useAddAddressModal = () => {
  const context = useContext(AddAddressModalContext);
  if (!context) {
    throw new Error('useAddAddressModal must be used within an AddAddressModalProvider');
  }
  return context;
};
