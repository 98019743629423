
export function compareVariations(variationIds1: number[] | null, variationIds2: number[] | null): boolean {

  // If both arrays are null, consider them equal
  if (variationIds1 === null && variationIds2 === null) {
    return true;
  }

  // If only one array is null or lengths are different, consider them not equal
  if (!variationIds1 || !variationIds2 || variationIds1.length !== variationIds2.length) {
    return false;
  }

  // Sort both arrays to ensure order doesn't affect comparison
  const sortedVariations1 = variationIds1.slice().sort();
  const sortedVariations2 = variationIds2.slice().sort();

  // Compare each element of the arrays
  for (let i = 0; i < sortedVariations1.length; i++) {
    if (sortedVariations1[i] !== sortedVariations2[i]) {
      return false;
    }
  }

  return true;
}






