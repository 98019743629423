import {
    getOrdersUsers,
    imageUpload,
    removeAddress,
    showAddresses, 
    showCatViseProducts,
    showOrderDetails,
    showSettings,
    showSliders as showSlidersEndpoint,
    profileUpdate ,
    getNearestBranch as getNearestBranchEndpoint,
} from "./endpoints";


export const getCatViseProducts = async () => {
    try {
        const res = await fetch(showCatViseProducts, {
            method: 'POST',            
            body: new URLSearchParams({
                user_phone: '+923402281180'
            }),
        });

        if (!res.ok) {
            throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json()
    } catch (error) {
        console.error('Fetching data failed:', error);
    }
}


export const showSliders = async () => {
    try {
        const res = await fetch(showSlidersEndpoint, {
            method: 'POST',      
           
        });
        return res.json()
    } catch (error) {
        console.error('Fetching data failed:', error);
    }
}


export const getSettings = async () => {
    try {
        const res = await fetch(showSettings, {
            // method: 'POST',
            // headers: {
            //     'Content-Type': 'application/x-www-form-urlencoded'
            // },
            cache: "no-store"   
        });

        if(res.status === 403) {
            return res
        }
        if (!res.ok) {

            throw new Error(`HTTP error! status: ${res.status}`);
        }

        return res.json()
    } catch (error) {
        console.error('Fetching data failed:', error);
    }
}

export const getNearestBranch = async (body) => {
    try {
        const res = await fetch(getNearestBranchEndpoint, {
            method: 'POST',
            body: body
        });

        return res.json()
    } catch (error) {
        console.error('Error getting nearest branch:', error);
    }
};
export const getAllAddresses = async (body) => {
    try {
        const res = await fetch(showAddresses, {
            method: 'POST',
            body: body
        });

        return res.json()
    } catch (error) {
        console.error('Error fetching addresses:', error);
    }
};

export const deleteAddress = async (body) => {
    try {
        const res = await fetch(removeAddress, {
            method: 'POST',
            body: body
        });

        return res.json()
    } catch (error) {
        console.error('Error deleting address:', error);
    }
};

export const getOrders = async (body) => {
    try {
        const res = await fetch(getOrdersUsers, {
            method: 'POST',
            body: body
        });

        return res.json()
    } catch (error) {
        console.error('Error fetching orders:', error);
    }
};

export const getOrderDetails = async (body) => {
    try {
        const res = await fetch(showOrderDetails, {
            method: 'POST',
            body: body
        });

        return res.json()
    } catch (error) {
        console.error('Error fetching order details:', error);
    }
};


export const uploadImage = async (body) => {
    try {
        const res = await fetch(imageUpload, {
            method: 'POST',
            body: body
        });

        return res.json()
    } catch (error) {
        console.error('Error uploading image:', error);
    }
};

export const updateProfile = async (body) => {
    try {
        const res = await fetch(profileUpdate, {
            method: 'POST',
            body: body
        });

        return res.json()
    } catch (error) {
        console.error('Error updating profile:', error);
    }
};