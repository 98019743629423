export const showCatViseProducts = `${process.env.NEXT_PUBLIC_API_BASE_URL}showCatViseProducts.php`
export const showSettings = `${process.env.NEXT_PUBLIC_API_BASE_URL}showSettings.php`
export const getAddress = `${process.env.NEXT_PUBLIC_API_BASE_URL}getaddress.php`
export const getNearestBranch = `${process.env.NEXT_PUBLIC_API_BASE_URL}getNearestBranch.php`
export const showAddresses = `${process.env.NEXT_PUBLIC_API_BASE_URL}showAddresses.php`
export const addNewAddress = `${process.env.NEXT_PUBLIC_API_BASE_URL}addAddress.php`
export const removeAddress = `${process.env.NEXT_PUBLIC_API_BASE_URL}removeAddress.php`
export const placeOrder = `${process.env.NEXT_PUBLIC_API_BASE_URL}placeOrder.php`
export const register = `${process.env.NEXT_PUBLIC_API_BASE_URL}register.php`
export const login = `${process.env.NEXT_PUBLIC_API_BASE_URL}login.php`
export const getOrdersUsers = `${process.env.NEXT_PUBLIC_API_BASE_URL}getOrdersUsers.php`
export const showOrderDetails = `${process.env.NEXT_PUBLIC_API_BASE_URL}showOrderDetails.php`
export const showSliders = `${process.env.NEXT_PUBLIC_API_BASE_URL}/showSliders.php`
export const imageUpload = `${process.env.NEXT_PUBLIC_API_BASE_URL}imageupload.php`
export const profileUpdate = `${process.env.NEXT_PUBLIC_API_BASE_URL}updateProfile.php`
